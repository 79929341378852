/* div prefixes so these will have greated precedence than the component styles themselves */

div.center-enter {
  padding: 0;
  background-color: #ffb100;
  transition: all 1000ms;
  transition-timing-function: ease-in-out;
  min-height: 0;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
}

div.center-enter .winner-popup-opening-content {
  display: flex;
}

div.center-enter .winner-popup-opening-content span {
  font-size: 18px;
  transform: scale(1.1, 1.1);
  transition: font-size 1000ms, opacity 1000ms;
  opacity: 1;
}

div.center-enter .winner-popup-open-content {
  max-height: 0;
  transition: opacity 1000ms 500ms;
  opacity: 0;
}

div.center-enter-active {
  max-height: 1000px;
  transform: translateX(-50%) translateY(-50%);
  background-color: #ffffff;
  width: 90%;
  left: 50%;
  top: 50%;
  padding: 0.5em;
  box-sizing: border-box;
  box-shadow: 20px 20px 0px rgba(0, 0, 0, 0.5);
}

div.center-enter-active .winner-popup-opening-content span {
  font-size: 0px;
  opacity: 0;
}

div.center-enter-active .winner-popup-open-content {
  max-height: 100%;
  opacity: 1;
}

div.fade-enter {
  opacity: 0;
}

div.fade-enter-active {
  opacity: 1;
}

div.fade-leave {
  opacity: 1;
}

div.fade-leave-active {
  opacity: 0;
}
