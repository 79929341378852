html,
body,
#root {
  height: 100vh;
  font-size: 14px;
  font-family: -apple-system;
  font-family: "proxima-nova", "-apple-system", "Helvetica Neue", sans-serif;
  color: #615f5f;
}
body {
  margin: 0;
}

@keyframes fadeIn {
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 0.4);
  }
}

@keyframes slideUp {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
}

html .ath-viewport {
  opacity: 1;
  background: rgba(0, 0, 0, 0.4);
  animation: fadeIn 300ms;
  animation-delay: 500ms;
}
html .ath-phone.ath-container,
html .ath-container {
  width: 100%;
  left: 0;
  bottom: 0;
  margin-left: 0;
  background: #fff;
  border-radius: 3px;
  padding: 3em;
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  animation: slideUp 300ms;
  animation-delay: 500ms;
}
html .ath-ios.ath-tablet:after,
html .ath-ios.ath-phone:after {
  width: 0;
  height: 0;
  bottom: 10px;
  top: auto;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 12px solid #cecece;
  margin-left: -5px;
  transform: none;
  background: none !important;
  box-shadow: none !important;
}

html .ath-container h3 {
  margin: 0;
  margin-bottom: 0.5em;
  margin-top: 15px;
  font-size: 20px;
}

html .ath-container p {
  text-shadow: none;
}

html .ath-container.ath-ios .ath-application-icon,
html .ath-application-icon {
  border-radius: 15px;
  margin-bottom: 1em;
  margin-left: 1em;
  margin-top: 1em;
  width: 70px;
  height: 70px;
  float: right;
}

html .ath-container.ath-android.ath-icon:before,
html .ath-container.ath-icon:before {
  width: 30px;
  left: 10px;
  top: 5px;
  background-color: transparent;
}

html .ath-ios.ath-phone:after {
  background: #2c9eff;
  box-shadow: 3px 0px 0 rgba(0, 0, 0, 0.1);
}
